// @ts-ignore
import $ from 'jquery';
import { loadSelect } from '@/lib/select-loader';
import { getMiddleCategories, getQuestionSubjects } from '@/lib/api/categories';

$(async () => {
  // 大分類,中分類をセレクトしたらその子供のセレクトが変わるように
  const largeSelect = document.getElementById('js-select-large-category') as HTMLSelectElement;
  const middleSelect = document.getElementById('js-select-middle-category') as HTMLSelectElement;
  if (largeSelect) {
    largeSelect.addEventListener('change', async () => {
      middleSelect.value = '';
      middleSelect.dispatchEvent(new Event('changeParent'));
      await loadSelect('js-select-middle-category', getMiddleCategories, largeSelect.value);
    });
    await loadSelect('js-select-middle-category', getMiddleCategories, largeSelect.value);

    if (middleSelect) {
      ['change', 'changeParent'].forEach((eventType) => {
        middleSelect.addEventListener(eventType, async () => {
          await loadSelect('js-select-question-subject', getQuestionSubjects, largeSelect.value, middleSelect.value);
        });
      });
      await loadSelect('js-select-question-subject', getQuestionSubjects, largeSelect.value, middleSelect.value);
    }
  }
});
